import * as React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { body } from '../../site/settings/terms.json'
import convertToHTML from 'markdown-to-html-converter'
const Terms = ({ location }) => {
  return (
    <Layout location={location} className="terms" title="Terms of conditions">
      <SEO title="Terms of conditions" location={location.href} />
      <div className="container">
        <div className="row">
          <div className="col-xxl-8 col-xl-8 col-sm-8 col-md-8">
            <div dangerouslySetInnerHTML={{ __html: convertToHTML(body) }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Terms
